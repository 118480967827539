@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  @apply h-screen font-roboto;
}

body,
#root {
  @apply h-full;
}

.tableWrap {
  @apply w-full py-2 bg-theme-gray-400 shadow-md;
}

tr {
  @apply w-full;
}

td {
  @apply py-2 tracking-tight align-top;
}

td:nth-child(1) {
  @apply w-1/3 font-light text-xs;
}

td:nth-child(2) {
  @apply text-sm font-semibold;
}
.twitter-icon svg {
  width: 1.0 rem; /* Adjust the size as needed */
  height: 1.0 rem; /* Maintain aspect ratio */
}
.subdata {
  @apply flex text-xs font-medium text-theme-gray-300;
}
.registerPageLinks > li > a {
  @apply underline hover:text-theme-blue;
}